<script setup lang="ts">
import { SpeedInsights } from '@vercel/speed-insights/nuxt'
import { getOrganisationSchema } from 'data/defaults/schema'

import { hasHeader, setWindowHeight } from 'lib/layout'
import { debounce } from 'lib/helpers/debounce'
import { wait } from 'lib/helpers/wait'
import type { PreviousInteraction } from 'types/newsletter'
import { ConfigProvider } from 'radix-vue'
import { DISABLE_NEWSLETTER_BLOCK_ON_PAGES, HIDE_NEWSLETTER_POPUP_FOR_STOREFRONT } from '~/configuration/global.configuration'

const route = useRoute()
const storyblokStore = useStoryblokStore()
const modalStore = useModalStore()
const storefrontStore = useStorefrontStore()
const mediaStore = useMediaStore()
const { $currentStorefront } = useNuxtApp()

const useIdFunction = () => useId()

const { showCookieBanner, blockModals, showNewsletter } = storeToRefs(modalStore)

const isTestEnv = route.query.test === 'true'

const newsletter = computed(() => storyblokStore.state.newsletter)
const blockedCountriesConstant = computed(() =>
  storyblokStore.getConstant(HIDE_NEWSLETTER_POPUP_FOR_STOREFRONT),
)
const blockedCountries = computed(() => blockedCountriesConstant.value?.value?.split(',') || [])
const isBlockedCountry = computed(() =>
  blockedCountries.value.includes($currentStorefront.storefrontCode),
)

const checkIsBlockedByModal = computed(() => {
  return modalStore.showFilterMenu || showCookieBanner.value || blockModals.value
})

const checkIsBlockedByURL = computed(() => {
  const isSkuPage = route.params.sku
  const isFaqNewsletterPage = ['/faq', '/newsletter'].some(link => route.fullPath.includes(link))
  return isSkuPage || isFaqNewsletterPage || isBlockedCountry.value
})

const isNewsletterPopupOpen = computed(() => {
  if (checkIsBlockedByModal.value || checkIsBlockedByURL.value)
    return false
  return showNewsletter.value
})

function resizeListener() {
  window.addEventListener('resize', setWindowHeight)
}

function getNewsletterPrevInteraction(): {
  type: PreviousInteraction['type']
  hoursPassed: number
} {
  try {
    const prevInteraction = localStorage.getItem('newsletterInteraction')
    const interaction: PreviousInteraction = JSON.parse(prevInteraction || '')
    const hoursPassed
  = Math.abs(new Date().getTime() - new Date(interaction.time).getTime()) / (60 * 60 * 1000)
    return { type: interaction.type, hoursPassed }
  }
  catch (_) {
    return { type: 'none', hoursPassed: 0 }
  }
}
async function updateNewsletterPopup() {
  if (showCookieBanner.value)
    return

  const { type, hoursPassed } = getNewsletterPrevInteraction()
  if (type === 'none' || (type === 'close' && hoursPassed > 48)) {
    const [minDelay, MaxDelay] = [5000, 10000]
    const popupDelay = Math.random() * (MaxDelay - minDelay) + minDelay
    await wait(popupDelay)
    showNewsletter.value = true
    return
  }
  showNewsletter.value = false
}

const showNewsletterBlock = computed(() => {
  const blockedRoutes = storyblokStore.getConstant(DISABLE_NEWSLETTER_BLOCK_ON_PAGES)?.value.split(',').filter(Boolean)

  if (blockedRoutes) {
    const path = route.path

    if (blockedRoutes.some(route => path.includes(route)))
      return false
  }

  return true
})

onMounted(() => {
  setWindowHeight()
  debounce(resizeListener, 300)
  updateNewsletterPopup()
})

onUnmounted(() => {
  window.removeEventListener('resize', setWindowHeight)
})

useHead({
  title: 'Happy Socks',
  htmlAttrs: {
    lang: storefrontStore.currentMarket.language,
  },
  script: [
    {
      innerHTML: JSON.stringify(getOrganisationSchema()),
      type: 'application/ld+json',
    },
  ],
})
</script>

<template>
  <ConfigProvider :use-id="useIdFunction">
    <div
      class="layout section" :class="[
        $route.name,
        {
          'checkout-page': $route.params.isCheckoutPage,
          'product-page': $route.params.isProductPage,
        },
      ]"
    >
      <SpeedInsights :sample-rate="0.2" />
      <HeaderMain v-if="hasHeader($route)" />
      <NuxtPage />
      <NewsletterBlock v-if="showNewsletterBlock" :blok="{ edge_style: 'teeth', component: 'newsletter-block' }" />
      <ClientOnly>
        <ModalFilterMenu />
        <ModalColorSelectorList />
        <ModalCountrySwitcher />
        <PageOverlay v-if="mediaStore.pageOverlay" />
        <NewsletterPopup v-if="isNewsletterPopupOpen && newsletter && !isTestEnv" tabindex="0" />
      </ClientOnly>
      <LazyFooter />
    </div>
  </ConfigProvider>
</template>

  <style lang="scss" scoped>
    @import 'assets/scss/rules/breakpoints';

    .layout {
      display: grid;
      min-height: 100vh;
    }

    .checkout-page .header-main {
      display: none;
    }

    @media (min-width: $tablet) {
      .checkout-page .header-main {
        display: grid;
      }
    }
</style>
